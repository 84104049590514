import Router, { RouteConfig } from 'vue-router'
import Vue from 'vue'

import AppHeader from "@/layout/AppHeader.vue";
import AppFooter from "@/layout/AppFooter.vue";
import AppHeaderCareers from "@/layout/careers/AppHeaderCareers.vue";

Vue.use(Router)

const StandarRouterConfigs: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        components: {
            header: AppHeader,
            default: () => import('@/views/home/index.vue'),
            footer: AppFooter
          }
    },
    {
      path: '/careers',
      name: 'careers',
      components: {
        header: AppHeaderCareers,
        default: () => import('@/views/careers/index.vue'),
        footer: AppFooter
      }
    },
    {
      path: '/currentJobs',
      name: 'currentJobs',
      components: {
        header: AppHeaderCareers,
        default: () => import('@/views/CurrentJobs/index.vue'),
        footer: AppFooter
      }
    }
]

const createRouter = () => 
    new Router({
        mode: 'history',
        linkExactActiveClass: "active",
        routes: StandarRouterConfigs,
        scrollBehavior: to => {
          if (to.hash) {
            return { selector: to.hash };
          } else {
            return { x: 0, y: 0 };
          }
        }
    })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher
}

export default router