
















import { Component, Vue } from 'vue-property-decorator';
import { FadeTransition } from "vue2-transitions";

@Component({
  name: 'App',
  components: {
    FadeTransition
  }
})
export default class extends Vue {}
